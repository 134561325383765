<template>

<app-panel v-on:close="$emit('close')" :loading="is.loading">
		
	<app-panel-title text="Create block" />

	<app-panel-item v-for="type in sortedTypes" :icon="$constants.block.icon[type.value]" :key="type.value" :text="type.text" v-on:click="onTypeClick(type)" />
		
</app-panel>

</template>

<script>

export default {
	
	props: ['types', 'page'],

	data: function() {

		return {
			is: {
				loading: false
			}
		}

	},

	computed: {

		sortedTypes: function() {

			var options = []

			this.$_.each(this.types, function(text, value) {

				options.push({
					value: value,
					text: text
				})
					
			}.bind(this))

			return this.$_.sortBy(options, 'text')

		}

	},

	methods: {

		onTypeClick: async function(type) {

			this.is.loading = true

			await this.$api.post(['convention/pages/page', this.$route.params.page, 'block/new'], {
				name: type.text,
				type: type.value
			})

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>
